import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../common/Checkbox/Checkbox';
import "./GEV.css";
import { OperationDataClass, OperationFieldProps } from '../../types/gev/BusinessOperationFormProps';
import { useBusinessOperationsState } from '../../utils/context/BusinessOperationsContext';
import { BusinessOperationsKeys } from '../../pages/BusinessOperations/BusinessOperations';

export default function GEVYesNo(props: OperationFieldProps): JSX.Element {
    const { operation, name } = props;
    const { t } = useTranslation('gev')
    const { formData, setFormData } = useBusinessOperationsState(); 
    const fieldLink: Record<string, string> = {
        "isTruckScaleOnSite" : "truckScaleCapacity",
        "areAnyBuildingsAddedOrRemoved" : "comments"
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.removeError && (name in fieldLink ? props.removeError(name, fieldLink[name]): props.removeError(name))
        const { checked, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [operation]: {
            ...prevData[operation as BusinessOperationsKeys],
            [name]: value === 'true' ? checked : !checked
            },
        }));
    };

    const currentValue = formData[operation as BusinessOperationsKeys]?.[name as keyof OperationDataClass] ?? '';

    return (
        <tr className='gev-input-tr'>
            <td className='index'>
                {props.index && props.index + "."}
            </td>
            <td>
                <div className='gev-input-label'>{t(operation + "." + name)}</div>
                <fieldset className='noborder-fieldset'>
                    <legend className='invisible-legend'><span>{t(operation + "." + name)}</span></legend>
                    <div className='gev-yesno'>
                        {t("yes")}&nbsp;&nbsp;
                        <Checkbox name={operation + "." + name} value="true"
                            checked={currentValue === true}
                            onChange={handleChange}
                            checkboxclassname={props.hasErrors ? 'form-error' : 'checkmark'}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {t("no")}&nbsp;&nbsp;
                        <Checkbox name={operation + "." + name} value="false"
                            checked={currentValue === false}
                            onChange={handleChange}
                            checkboxclassname={props.hasErrors ? 'form-error' : 'checkmark'}
                        />
                    </div>
                </fieldset>
                <div className="input-error-message">
                    {props.errors && props.errors[name] as string}
                </div>
            </td>
        </tr>
    )
}
