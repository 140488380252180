import { SET_ACTIVE_MENU_ITEM, SET_MENU, SET_NEXT_ACTIVE_MENU_ITEM, SET_PREV_ACTIVE_MENU_ITEM, UPDATE_MENU_ITEM_STATUS } from "../../actionTypes/actionsTypes";
import { LocalAction } from "../../types";
import MenuItem from "../../../types/menu/MenuItem";
import MenuState from "../../../types/menu/MenuState";

export const initialMenuItem: MenuItem = {
  id: 0,
  name: 'NotFound',
  complete: false
}

const initialState: MenuState = {
  activeItem: initialMenuItem,
  nextActiveItem: initialMenuItem,
  prevActiveItem: initialMenuItem,
  menu: []
}

function getMenuReducer(state = initialState, action: LocalAction) {
  switch (action.type) {
    case SET_MENU:
      return {
        ...state,
        menu: action.data
      }
    case SET_ACTIVE_MENU_ITEM:
      return {
        ...state,
        activeItem: action.data,
        nextActiveItem: initialMenuItem
      }
    case SET_NEXT_ACTIVE_MENU_ITEM:
      return {
        ...state,
        nextActiveItem: action.data
      }
    case SET_PREV_ACTIVE_MENU_ITEM:
      return {
        ...state,
        prevActiveItem: action.data
      }
    case UPDATE_MENU_ITEM_STATUS:
      return state.menu.map((item) => {
        if (item.id === action.data?.id) {
          return {
            ...item,
            complete: action.data.complete
          };
        }
        return item;
      });
    default:
      return state;
  }
}

export default getMenuReducer;
